import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function EventTabs(props) {
  const [key, setKey] = useState('contact');

  return (
    <Tabs
      id="event-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3" 
      style={{ flexGrow: 0, minHeight: '50px', overflowY: 'auto', marginLeft: "20px" }}
      variant='underline'
    >
      <Tab eventKey="contact" title="Contact Info">
      <div style={{ minHeight: '80px', marginLeft:"20px" }}>
        <p>{props.data.eventDetails.organiserName}</p>
        <p>{props.data.eventDetails.organiserPhone}</p>
        <p>{props.data.eventDetails.address}</p>
      </div>
      </Tab>
      <Tab eventKey="amneties" title="Amenities">
      <div style={{ minHeight: '80px',marginLeft:"20px" }}> 
        Tab content for Amenities
      </div>
      </Tab>
    </Tabs>
  );
}

export default EventTabs;